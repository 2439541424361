import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService {
  private hashKey = environment.storageHashKey;

  constructor() {}

  /**
   * Save a token or object in a cookie
   * @param key - The key for the cookie
   * @param object - The object to store
   * @param expiresInDays - The expiration time in days (default: 7)
   */
  save(key: string, object: any, expiresInDays: number = 7): void {
    const json = JSON.stringify(object);
    const encryptedJson = this.encryptJson(json);
    this.setCookie(key, encryptedJson, expiresInDays);
  }

  /**
   * Read a token or object from a cookie
   * @param key - The key of the cookie
   */
  read<T>(key: string): T | null {
    const encryptedJson = this.getCookie(key);
    if (!encryptedJson) {
      return null;
    }
    const decryptedJson = this.decryptJson(encryptedJson);
    return decryptedJson ? JSON.parse(decryptedJson) : null;
  }

  /**
   * Remove a cookie by key
   * @param key - The key of the cookie
   */
  remove(key: string): void {
    this.setCookie(key, '', -1); // Setting expiration in the past removes the cookie
  }

  /**
   * Encrypt JSON data
   * @param json - JSON string to encrypt
   */
  private encryptJson(json: string): string {
    return CryptoJS.AES.encrypt(json, this.hashKey).toString();
  }

  /**
   * Decrypt JSON data
   * @param encryptedJson - Encrypted string to decrypt
   */
  private decryptJson(encryptedJson: string): string {
    try {
      return CryptoJS.AES.decrypt(encryptedJson, this.hashKey).toString(
        CryptoJS.enc.Utf8,
      );
    } catch (err) {
      console.error('Decryption failed:', err);
      return '';
    }
  }

  /**
   * Set a cookie
   * @param key - The key of the cookie
   * @param value - The value of the cookie
   * @param expiresInDays - Expiration time in days
   */
  private setCookie(key: string, value: string, expiresInDays: number): void {
    const date = new Date();
    date.setTime(date.getTime() + expiresInDays * 24 * 60 * 60 * 1000);
    const expires = expiresInDays ? `; expires=${date.toUTCString()}` : '';
    document.cookie = `${key}=${value}${expires}; path=/; Secure; SameSite=Strict`;
  }

  /**
   * Get a cookie value by key
   * @param key - The key of the cookie
   */
  private getCookie(key: string): string | null {
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (const cookie of cookieArray) {
      let c = cookie.trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
}
